<template>
  <!-- <NavbarPart /> -->
  <!-- <CarouselPart  />
  <ContentPart /> -->
  <div>
    <div class="px-8 md:px-14 lg:px-14 xl:px-14 2xl:px-14 mt-5">
      <div class="navbar bg-base-100">
        <div class="flex-1">
          <router-link to="/">
            <a class="normal-case"><img
              src="../assets/images/logos/pasid.png"
              style="height: 58px;"
            /></a>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-10 w-full py-10">
      <div>
        <section
          id="information"
          class="prose w-full max-w-full mb-10"
        >
          <h2
            class="text-black"
            style="font-size: 70px;"
          >
            Privacy Policy
          </h2>
          <hr
            class="divider mt-8 mb-5"
          />
          <p>
            Effective Date: Nov 2023
          </p>
          <h2 class="text-lg font-semibold mb-4">
            1. Introduction
          </h2>
          <p>
            Maxima Inditech Media, the developer of Sikopi Pasid ("App"), is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and store your information when you use the App and the choices you have associated with your information.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            2. Information We Collect
          </h2>
          <p>
            We collect the following information when you use the App:
            <br />
            a. <strong>Personal Information:</strong> You may provide your name, email address, phone number, and other contact details when you register for an account or use certain features of the App.
            <br />
            b. <strong>Device Information:</strong> We collect information about your device, such as your device ID, operating system, IP address, and browser type.
            <br />
            c. <strong>Usage Data:</strong> We collect information about how you use the App, such as the features you access, the pages you visit, and the time you spend on the App.
            <br />
            d. <strong>Cookie Information:</strong> We may use cookies and similar tracking technologies to collect information about your browsing activity and interactions with the App. This may include information about the pages you visit, the links you click, and the searches you conduct.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            3. How We Use Your Information
          </h2>
          <p>
            We use the information we collect to:
            <br />
            a. <strong>Provide and improve the App and its features.</strong>
            <br />
            b. <strong>Process payments and transactions.</strong>
            <br />
            c. <strong>Send you important information about the App, such as updates, security alerts, and support messages.</strong>
            <br />
            d. <strong>Respond to your inquiries and requests.</strong>
            <br />
            e. <strong>Analyze how you use the App to improve our services.</strong>
            <br />
            f. <strong>Personalize your experience with the App.</strong>
            <br />
            g. <strong>Deliver targeted advertising (if applicable).</strong>
          </p>
          <h2 class="text-lg font-semibold mb-4">
            4. Sharing Your Information
          </h2>
          <p>
            We may share your information with third-party service providers who help us operate the App, such as data storage providers, payment processors, and analytics providers. These third-party service providers are obligated to use your information only for the purpose of providing services to us and in accordance with this Privacy Policy.
            <br />
            We may also disclose your information to law enforcement or regulatory authorities if required by law.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            5. Children's Privacy
          </h2>
          <p>
            We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us. We will take steps to delete the information from our servers.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            6. Your Rights
          </h2>
          <p>
            You have the right to:
            <br />
            a. <strong>Access your personal information.</strong>
            <br />
            b. <strong>Rectify inaccurate personal information.</strong>
            <br />
            c. <strong>Request the deletion of your personal information.</strong>
            <br />
            d. <strong>Object to the processing of your personal information.</strong>
            <br />
            e. <strong>Restrict the processing of your personal information.</strong>
            <br />
            f. <strong>Withdraw your consent to the processing of your personal information (where applicable).</strong>
          </p>
          <h2 class="text-lg font-semibold mb-4">
            7. Data Security
          </h2>
          <p>
            We take reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no internet or electronic storage system is completely secure.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            8. Data Retention
          </h2>
          <p>
            We will retain your information for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            9. Updates to this Privacy Policy
          </h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App.
          </p>
          <h2 class="text-lg font-semibold mb-4">
            10. Contact Us
          </h2>
          <p>
            If you have any questions about this Privacy Policy or our data practices, please contact us at sikopipasid@gmail.com.
          </p>
          <!-- <h2 class="text-lg font-semibold mb-4">10. Governing Law</h2> -->
        </section>
      </div>
    </div>
    <div class="bg-footer">
      <footer class="container mx-auto px-5">
        <div class="pt-6">
          <img
            src="../assets/images/logos/pasid.png"
            class="min-w-48 w-5"
          />
        </div>
        <hr class="divider mt-8 mb-5" />
        <div
          class="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start md:items-center lg:items-center xl:items-center 2xl:items-center"
        >
          <div class="flex-none w-full md:w-2/4 prose">
            <span
              class="text-white hidden md:block lg:block xl:block 2xl:block mb-2 mr-auto text-sm"
            >© SikopiPasid 2023
            </span>
          </div>
        </div>
      </footer>
    </div>
  </div>
  <!-- <FooterPart /> -->
</template>

<script>
// import NavbarPart from '../components/Navbar.vue'
// import CarouselPart from '../components/Carousel.vue'
// import ContentPart from '../components/Content.vue'
// import FooterPart from '../components/Footer.vue'
// import { onMounted, ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
    // NavbarPart,
    // CarouselPart,
    // ContentPart,
    // FooterPart,
  },
  data() {
    return {}
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
html {
  scroll-behavior: smooth;
}
.bg-footer {
  background: rgb(4, 130, 117);
  background: linear-gradient(156deg, rgb(61, 182, 14) 0%, rgb(53, 16, 78) 100%);
}

.divider:before,
.divider:after {
  background-color: #ffffff69 !important;
  opacity: 0.8 !important;
  height: 0.02rem !important;
}
</style>
